import { useEffect, useState } from 'react';
import styles from './PswDimenticata.module.css';
import Card from '../UI/Card';
import GenericButton from '../UI/GenericButton';
import { getServerMgr } from '../../backend_conn/ServerMgr';

function PswDimenticata(props){
    const [validEmail, setValidEmail] = useState(true);
    const [email, setEmail] = useState('');

    useEffect(() => {
        setValidEmail(true);
    }, [email])

    const goToLoginForm = () => {
        // console.log("VAI AL FORM PER LOGGARE");
        props.goToLoginForm();
        // props.onShowMe('FORM-LOG_in');
    }

    const emailChangeHandler = (event) =>{
        setEmail(event.target.value);
    }

    const submitPswRecovery = async (event) => {
        event.preventDefault();

        let result;
        let result2;
        let result3;
        result = await getServerMgr().pswRecovery_checkEmail(email)
        .catch((err) => {
            console.error(err)
        });

        if(result !== null){
            console.log(result);
            result2 = await getServerMgr().pswRecovery_code(email)
            .catch((err) => {
                console.error(err)
            });
            if(result2 !== null){
                console.log(result2);
                result3 = await getServerMgr().updateCode(email)
                .catch((err) => {
                    console.error(err)
                });
                alert("Email di reset password inviata!")
            }
            else{
                console.log("PRIMO CODICE")
                result3 = await getServerMgr().insertFirstCode(email)
                .catch((err) => {
                    console.error(err)
                });
                alert("Email di reset password inviata!")
            }
        }
        else{
            alert("Non è stata trovato un account corrispondente a questa email")
            console.log("NESSUNA EMAIL");
        }
    }

    return(
        <Card
        children = {
            <form className={styles.center_elements} onSubmit={submitPswRecovery}>
                <h1 className={styles.title}>Recupero Password</h1>

                <label className={`${styles.label_box} ${!validEmail ? styles.invalid : ''}`}>Email</label>
                <input className={`${styles.input_box} ${!validEmail ? styles.invalid : ''}`} type="email" placeholder="Inserisci email" value={email} onChange={emailChangeHandler}></input>

                {!validEmail && <h2 style={{color: "red"}}>Inserisci una email valida!</h2>}

                <GenericButton
                    type = "submit"
                    generic_button={true}
                    buttonText = 'Invia'
                >
                </GenericButton>
                
                <h5 className={styles.log_reg} onClick={goToLoginForm}>Vai al Login</h5>
            </form>
        }>
        </Card>
    );
}

export default PswDimenticata;